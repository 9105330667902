
@import 'abstracts/variables';
@import 'base/typography';


@font-face{
    font-family:'Roboto';src:url('https://cdn.avt.pl/fonts/RobotoRegular.eot');
    src:url('https://cdn.avt.pl/fonts/RobotoRegular.eot') format('embedded-opentype'),
    url('https://cdn.avt.pl/fonts/RobotoRegular.woff2') format('woff2'),
    url('https://cdn.avt.pl/fonts/RobotoRegular.woff') format('woff'),
    url('https://cdn.avt.pl/fonts/RobotoRegular.ttf') format('truetype'),
    url('https://cdn.avt.pl/fonts/RobotoRegular.svg#RobotoRegular') format('svg');
    fotn-weight:normal
}

@font-face{
    font-family:'Roboto';
    src:url('https://cdn.avt.pl/fonts/RobotoBold.eot');
    src:url('https://cdn.avt.pl/fonts/RobotoBold.eot') format('embedded-opentype'),
    url('https://cdn.avt.pl/fonts/RobotoBold.woff2') format('woff2'),
    url('https://cdn.avt.pl/fonts/RobotoBold.woff') format('woff'),
    url('https://cdn.avt.pl/fonts/RobotoBold.ttf') format('truetype'),
    url('https://cdn.avt.pl/fonts/RobotoBold.svg#RobotoBold') format('svg');
    font-weight:bold
}

@font-face{
    font-family:'Roboto';
    src:url('https://cdn.avt.pl/fonts/RobotoThin.eot');
    src:url('https://cdn.avt.pl/fonts/RobotoThin.eot') format('embedded-opentype'),
    url('https://cdn.avt.pl/fonts/RobotoThin.woff2') format('woff2'),
    url('https://cdn.avt.pl/fonts/RobotoThin.woff') format('woff'),
    url('https://cdn.avt.pl/fonts/RobotoThin.ttf') format('truetype'),
    url('https://cdn.avt.pl/fonts/RobotoThin.svg#RobotoThin') format('svg');
    font-weight:100
}
* {
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box
}
html,body{
    height:100%
}
body{
    font-family:'Roboto';
    font-size:16px;
    font-weight:normal;
    line-height:1;
    color:#333333;
    margin:0;
    background-color:#fff;
}
.wrap{
    max-width:1200px;
    padding:0 15px;
    margin:0 auto;
    height:100%;
    font-size:0;
}.wrap:before {
    display:inline-block;
    content:'';
    height:100%;
    vertical-align:middle;
}
.wrap .cell{
    display:inline-block;
    padding:20px 0;
    vertical-align:middle;
    width:100%;
}
.logo-error{
    display:block;
    width:113px;
    margin:0 auto;
}
.ups{
    display:block;
    font-size:20px;
    color:#323232;
    text-align:center;
    margin:24px 0 0;
    border-bottom:6px solid #d6d6d6;
    padding:0 0 18px;
    line-height:1.2;
}
.img-error{
    display:block;
    max-width:100%;
    height:auto;
    margin:30px auto 0;
}
.home-url{
    max-width:465px;
    margin:30px auto 0;
    text-align:center;
    font-size:20px;
    color:#333333;
    line-height:1.2;
}
.home-url a{
    text-decoration:none;
    color:#0375bb;
}
.home-url a:hover{
    text-decoration:underline;
}
.error-search{
    max-width:465px;
    width:100%;
    margin:20px auto 0;
    box-shadow:0 20px 80px rgba(0,0,0,0.3);
}
.error-search button{
    position:relative;
    overflow:hidden;
    display:block;
    float:right;
    width:122px;
    height:48px;
    text-transform:uppercase;
    color:#fff;
    font-size:14px;
    border:none;
    cursor:pointer}
.error-search button .bg{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:block;
    background:#26eced;
    background:-moz-linear-gradient(-45deg, #26eced 0, #00b4ff 100%);
    background:-webkit-linear-gradient(-45deg, #26eced 0, #00b4ff 100%);
    background:linear-gradient(135deg, #26eced 0, #00b4ff 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#26eced', endColorstr='#00b4ff', GradientType=1);
    -moz-transition:all .2s ease 0s;
    -ms-transition:all .2s ease 0s;
    -webkit-transition:all .2s ease 0s;
    -o-transition:all .2s ease 0s;
    transition:all .2s ease 0s}
.error-search button .t{
    position:relative;
    z-index:2;
}
.error-search button:hover .bg{
    width:120%;
}
.error-search .for-input{
    overflow:hidden;
}
.error-search input[type="text"],
.error-search input[type="email"]{
    display:block;
    width:100%;
    height:48px;
    border:1px solid #d6d6d6;
    border-right:none;
    padding:0 18px;
    color:#444;
    outline:none;
    font-size:18px;
}
.error-debug{
    margin-top:25px;
    color:#000;
    font-size:16px;
    text-align:left;
}
.error-debug-file{
    color:#ed2025;
    text-align:center;
}
.error-debug table{
    margin:0 auto;
}
.error-debug table td{
    padding:5px;
}
.error-debug table tr:nth-child(2){
    background-color:#eee;
}
.error-debug table tr:not(:first-child) td:first-child{
    color:#707070;
}
.error-debug table tr:not(:nth-child(2)) td:nth-child(2){
    color:#ed2025;
}
.error-debug table tr:hover{
    background-color:#c4f1ff;
}
.error-code{
    margin:10px 0 0 0;
    font-size:300px;
    font-weight:900;
    text-align:center;
}

@media (min-width:1025px){
    .wrap .cell {
        padding:50px 0;
    }
    .ups {
        font-size:24px;
    }
    .img-error{
        margin:70px auto 0;
    }
    .home-url{
        margin:70px auto 0;
    }
    .error-search{
        margin:30px auto 0;
    }
}