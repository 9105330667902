@font-face {
    font-family: 'Roboto';
    src: url('https://cdn.avt.pl/fonts/RobotoThin.eot');
    src: url('https://cdn.avt.pl/fonts/RobotoThin.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/RobotoThin.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/RobotoThin.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/RobotoThin.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/RobotoThin.svg#RobotoThin') format('svg');
    font-weight: 100;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('https://cdn.avt.pl/fonts/RobotoLight.eot');
    src: url('https://cdn.avt.pl/fonts/RobotoLight.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/RobotoLight.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/RobotoLight.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/RobotoLight.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/RobotoLight.svg#RobotoLight') format('svg');
    font-weight: 300;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('https://cdn.avt.pl/fonts/RobotoRegular.eot');
    src: url('https://cdn.avt.pl/fonts/RobotoRegular.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/RobotoRegular.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/RobotoRegular.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/RobotoRegular.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/RobotoRegular.svg#RobotoRegular') format('svg');
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('https://cdn.avt.pl/fonts/RobotoMedium.eot');
    src: url('https://cdn.avt.pl/fonts/RobotoMedium.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/RobotoMedium.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/RobotoMedium.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/RobotoMedium.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/RobotoMedium.svg#RobotoMedium') format('svg');
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('https://cdn.avt.pl/fonts/RobotoBold.eot');
    src: url('https://cdn.avt.pl/fonts/RobotoBold.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/RobotoBold.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/RobotoBold.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/RobotoBold.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/RobotoBold.svg#RobotoBold') format('svg');
    font-weight: 700;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('https://cdn.avt.pl/fonts/RobotoBlack.eot');
    src: url('https://cdn.avt.pl/fonts/RobotoBlack.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/RobotoBlack.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/RobotoBlack.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/RobotoBlack.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/RobotoBlack.svg#RobotoBlack') format('svg');
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.eot');
    src: url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.svg#RobotoCondensedRegular') format('svg');
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto Condensed';
    src: url('https://cdn.avt.pl/fonts/RobotoCondensedBold.eot');
    src: url('https://cdn.avt.pl/fonts/RobotoCondensedBold.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/RobotoCondensedBold.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/RobotoCondensedBold.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/RobotoCondensedBold.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.svg#RobotoCondensedBold') format('svg');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('https://cdn.avt.pl/fonts/LatoLight.eot');
    src: url('https://cdn.avt.pl/fonts/LatoLight.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/LatoLight.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/LatoLight.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/LatoLight.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/LatoLight.svg#LatoLight') format('svg');
    font-weight: 300;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('https://cdn.avt.pl/fonts/LatoLight.eot');
    src: url('https://cdn.avt.pl/fonts/LatoLight.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/LatoLight.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/LatoLight.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/LatoLight.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/LatoLight.svg#LatoLight') format('svg');
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('https://cdn.avt.pl/fonts/LatoRegular.eot');
    src: url('https://cdn.avt.pl/fonts/LatoRegular.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/LatoRegular.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/LatoRegular.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/LatoRegular.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/LatoRegular.svg#LatoRegular') format('svg');
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('https://cdn.avt.pl/fonts/LatoBold.eot');
    src: url('https://cdn.avt.pl/fonts/LatoBold.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/LatoBold.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/LatoBold.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/LatoBold.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/LatoBold.svg#LatoBold') format('svg');
    font-weight: 700;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('https://cdn.avt.pl/fonts/LatoBlack.eot');
    src: url('https://cdn.avt.pl/fonts/LatoBlack.eot') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/LatoBlack.woff2') format('woff2'),
         url('https://cdn.avt.pl/fonts/LatoBlack.woff') format('woff'),
         url('https://cdn.avt.pl/fonts/LatoBlack.ttf') format('truetype'),
         url('https://cdn.avt.pl/fonts/LatoBlack.svg#LatoBlack') format('svg');
    font-weight: 900;
    font-display: swap;
}
@font-face {
    font-family: 'FontAwesome';
    src: url('https://cdn.avt.pl/fonts/fontawesome-webfont.eot?v=4.7.0');
    src: url('https://cdn.avt.pl/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-facebook-square:before     { content: "\f082"; }
.fa-pinterest:before           { content: "\f0d2"; }
.fa-instagram:before           { content: "\f16d"; }
.fa-shopping-cart:before       { content: "\f07a"; }
.fa-newspaper-o:before         { content: "\f1ea"; }
.fa-search:before              { content: "\f002"; }
.fa-link:before                { content: "\f0c1"; }
.fa-font:before                { content: "\f031"; }
.fa-print:before               { content: "\f02f"; }
.fa-check:before               { content: "\f00c"; }
.fa-chevron-right:before       { content: "\f054"; }
.fa-chevron-left:before        { content: "\f053"; }
.fa-times:before               { content: "\f00d"; }
.fa-bars:before                { content: "\f0c9"; }
.fa-cloud:before               { content: "\f0c2"; }
.fa-leaf:before                { content: "\f06c"; }
.fa-plus-circle:before         { content: "\f055"; }
.fa-long-arrow-right:before    { content: "\f178"; }
.fa-plus:before                { content: "\f067"; }
.fa-times-circle:before        { content: "\f057"; }
.fa-envelope:before            { content: "\f0e0"; }
.fa-facebook:before            { content: "\f09a"; }
.fa-google-plus:before         { content: "\f0d5"; }
.fa-twitter:before             { content: "\f099"; }
.fa-comment:before             { content: "\f075"; }
.fa-th:before                  { content: "\f00a"; }
.fa-caret-down:before          { content: "\f0d7"; }
.fa-youtube:before             { content: "\f16a"; }
.fa-long-arrow-alt-left:before { content: "\f177"; }
.fa-minus:before               { content: "\f068"; }
